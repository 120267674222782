<template>
  <div v-if="visible" class="modal-overlay">
    <div class="modal-content">
      <p>{{ message }}</p>
      <button @click="closeModal" class="generic-button">OK</button>
    </div>
  </div>
</template>
  
  <script>
export default {
  props: {
    promptCode: {
      type: String,
      required: true,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    message() {
      switch (this.promptCode) {
        case 'password-reset':
          return 'Password reset email sent. Please check your email.'
        case 'error':
          return 'An error occurred. Please try again.'
        default:
          return 'Operation completed successfully.'
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit('close')
    },
  },
}
</script>
  
 