// src/store.js
import { createStore } from 'vuex';
import { auth } from './firebase'; // Import Firebase Auth
import router from './router'; // Import the router to handle redirection

export default createStore({
  state: {
    user: null, // User object
  },
  mutations: {
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {
    // Track Firebase auth state with proper handling
    trackAuthState({ commit }) {
      const checkAuthState = async () => {
        if (auth.currentUser) {
          try {
            // Force a reload of the user state from Firebase
            await auth.currentUser.reload();
            const user = auth.currentUser;
            if (user) {
              console.log('User check');
              commit('setUser', user); // Set the user in Vuex if authenticated
            } else {
              console.log('User no longer valid after reload.');
              commit('setUser', null); // Clear the user if logged out or account deleted
              router.push({ name: 'landing' }); // Redirect to login page
            }
          } catch (error) {
            // If an error occurs (user-token-expired or similar), log out and redirect
            console.log('Error reloading user:', error);
            commit('setUser', null);
            if (error.code === 'auth/user-token-expired') {
              console.log('Token expired, redirecting to login');
              router.push({ name: 'landing' }); // Force redirect to login
            }
          }
        }
      };

      // Use onAuthStateChanged to handle sign-in and out automatically
      auth.onAuthStateChanged((user) => {
        console.log('Auth state changed.');
        if (user) {
          commit('setUser', user); // User is logged in
        } else {
          commit('setUser', null); // User is logged out or deleted
          router.push({ name: 'landing' }); // Redirect to login when logged out
        }
      });

      // Poll Firebase every 5 seconds to check if the account was deleted
      setInterval(checkAuthState, 5000);
    },
  },
  getters: {
    user: (state) => state.user,
  },
});
