// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import UserLogin from '../components/UserLogin.vue';
import MainMenu from '../components/MainMenu.vue';
import PrivacyPolicy from '../components/PrivacyPolicy.vue';
import store from '../store'; // Import Vuex store

const routes = [
  {
    path: '/',
    name: 'landing',
    component: UserLogin,
  },
  {
    path: '/menu',
    component: MainMenu,
    meta: { requiresAuth: true }, // Protect this route
  },
  { path: '/privacy', 
    name: 'PrivacyPolicy', 
    component: PrivacyPolicy 
  }, 
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// Global navigation guard to ensure the user is authenticated
router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters.user; // Get user from Vuex
  //   console.log('Routing to:', to.path, 'Authenticated user:', isAuthenticated);

  if (to.matched.some((record) => record.meta.requiresAuth) && !isAuthenticated) {
    console.log('User not authenticated. Redirecting to login.');
    next({ name: 'landing' }); // Redirect to login if not authenticated
  } else {
    next(); // Allow access otherwise
  }
});

export default router;
