<template>
  <router-view />
</template>

<script>
export default {
  name: 'App'
};
</script>

<style>
/* see global.css */
</style>
