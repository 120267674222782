// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider  } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDriVRm7qdfjHWxKB_6LOsnEU_tkz1_Mvs",
    authDomain: "debatejungle-24.firebaseapp.com",
    projectId: "debatejungle-24",
    storageBucket: "debatejungle-24.appspot.com",
    messagingSenderId: "892178817605",
    appId: "1:892178817605:web:0a0e9e479c839b3494b92c"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firestore
const db = getFirestore(app);
// Initialize auth
const auth = getAuth(app);

// Initialize Providers
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const twitterProvider = new TwitterAuthProvider();

export { db, auth, googleProvider, facebookProvider, twitterProvider };
