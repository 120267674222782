<template>
  <div class="privacy-policy-container">
    <h2>Privacy Policy</h2>
    <p>
      <!-- Paste the Privacy Policy content here -->
      Effective Date: 09/26/2024
    </p>
    <h3>Introduction</h3>
    <p>Welcome to Debate Jungle! Your privacy is important to us. This Privacy Policy explains how we collect, use, and safeguard your information when you use our app. By using our services, you consent to the practices described in this policy.</p>

    <h3>Information We Collect</h3>

    <h3>Personal Information:</h3>
    <p>When you sign in using Facebook, we collect only the minimum required data: your email address and public profile information. This information is used solely for authentication purposes.</p>
  
    <h3>Non-Personal Information:</h3>
    <p>We may collect general, non-identifiable information, such as device type, browser type, and usage statistics. This data is used to improve our service.</p>

    <h3>How We Use Your Information</h3>

    <h3>Authentication:</h3>
    <p>The information collected during sign-in is used exclusively for verifying your identity and allowing access to the app.</p>
  
    <h3>Communication:</h3>
    <p>We may use your email address to send you important notifications about updates or changes to our services.</p>
    <h3>Information Sharing and Disclosure:</h3>
    <p>We do not sell, trade, or otherwise transfer your personal information to outside parties. Your information will only be shared when required by law or if it is necessary for providing our service (e.g., if required by our service providers).</p>
    <h3>Data Security:</h3>
    <p>We implement various security measures to maintain the safety of your personal information. All data transmission is encrypted, and we ensure that any information we collect is securely stored.</p>

    <h3>Third-Party Services:</h3>

    <p>Our app may include links to third-party services, such as Facebook for authentication. These services have their own privacy policies, and we encourage you to review them before using their services. We are not responsible for the privacy practices of these third-party services.</p>

    <h3>Children’s Privacy:</h3>

    <p>Our app is not intended for children under 13. We do not knowingly collect personal information from children under 13. If you believe we have inadvertently collected such information, please contact us at support@debatejungle.com to have it removed.</p>

    <h3>Your Consent:</h3>

    <p>By using our app, you consent to our Privacy Policy.</p>

    <h3>Changes to This Policy:</h3>

    <p>We may update this Privacy Policy periodically. Any changes will be reflected on this page with an updated effective date. Please review this policy regularly to stay informed of any changes.</p>

    <h3>Contact Us:</h3>

    <p>If you have any questions about this Privacy Policy, please contact us at support@debatejungle.com.</p>
  </div>
</template>
  
  <script>
export default {
  name: 'PrivacyPolicy',
}
</script>
  
  