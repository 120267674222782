<template>
  <div>
    <h1>Welcome to the Menu</h1>
    <p>Select an option to proceed.</p>
    <button @click="logout" class="logout-button">Logout</button>
  </div>
</template>

<script>
import { useStore } from 'vuex'; // To access Vuex store
import { auth } from '../firebase'; // Firebase Auth

export default {
  setup() {
    const store = useStore(); // Access Vuex store

    const logout = async () => {
      try {
        await auth.signOut(); // Sign out from Firebase
        store.commit('setUser', null); // Clear user from Vuex
        console.log('User logged out successfully');
      } catch (error) {
        console.error('Error logging out:', error);
      }
    };

    return {
      logout,
    };
  },
};
</script>

<style scoped>

</style>
