<template>
  <div class="login-container">
    <div class="welcome-text-container">
      <div class="welcome-text">
        <p ref="welcomeLine1" class="typing"></p>
        <p ref="welcomeLine2" class="typing"></p>
      </div>
    </div>

    <!-- Use the new LoginForm component -->
    <LoginForm />
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import LoginForm from './LoginForm.vue'; // Import the LoginForm component

export default {
  components: {
    LoginForm,
  },
  setup() {
    const line1 = 'Welcome to the Jungle.';
    const line2 = 'Will your ideas survive?';
    const typingSpeed = 100; // Speed of typing in milliseconds

    const welcomeLine1 = ref(null);
    const welcomeLine2 = ref(null);
    let typingInterval1 = null;
    let typingInterval2 = null;

    const typeText = (element, text, callback) => {
      let index = 0;
      const typingInterval = setInterval(() => {
        if (element.value) {
          element.value.textContent += text[index];
          index++;
          if (index === text.length) {
            clearInterval(typingInterval);
            if (callback) {
              callback();
            }
          }
        }
      }, typingSpeed);
      return typingInterval;
    };

    onMounted(() => {
      typingInterval1 = typeText(welcomeLine1, line1, () => {
        typingInterval2 = typeText(welcomeLine2, line2, () => {});
      });
    });

    onBeforeUnmount(() => {
      // Clear intervals when the component is destroyed to avoid errors
      if (typingInterval1) clearInterval(typingInterval1);
      if (typingInterval2) clearInterval(typingInterval2);
    });

    return {
      welcomeLine1,
      welcomeLine2,
    };
  },
};
</script>
