<template>
  <form @submit.prevent="submitForm">
    <div class="input-group">
      <!-- Google Sign-in Button -->
      <button type="button" @click="signInWithGoogle" class="provider-signin-button">
        <i class="fab fa-google"></i> Continue with Google
      </button>
      <button type="button" @click="signInWithTwitter" class="provider-signin-button">
        <i class="fa-brands fa-x-twitter"></i> Continue with X/Twitter
      </button>
      <button type="button" @click="signInWithFacebook" class="provider-signin-button">
        <i class="fab fa-facebook"></i> Continue with Facebook
      </button>
    </div>
    <div class="separator">Or, signin with email</div>
    <div class="input-group">
      <!-- Email input -->
      <input v-model="email" type="email" placeholder="Email" class="input-field" aria-label="Email" />
      <!-- Display email validation error -->
      <div v-if="v$.debouncedEmail.$error" class="error-message">{{ v$.debouncedEmail.$errors[0]?.$message }}</div>
    </div>
    <div class="input-group">
      <!-- Password input -->
      <input v-model="password" type="password" placeholder="Password" class="input-field" @input="validatePassword" aria-label="Password" />
      <!-- Display password length error during sign-up -->
      <div v-if="isSignUp && v$.password.$error" class="error-message">{{ v$.password.$errors[0]?.$message }}</div>
    </div>
    <!-- Forgot Password link -->
    <button type="button" class="forgot-password-button" @click="sendPasswordReset" v-if="showForgotPassword" :disabled="isCooldownActive">
      Forgot Password
      <span v-if="isCooldownActive">({{ cooldownTime }}s)</span>
    </button>

    <!-- Modal for displaying the password reset, etc prompt -->
    <ModalPopup :promptCode="promptCode" :visible="isModalVisible" @close="closeModal" />

    <div class="input-group">
      <!-- Password confirmation input for sign-up -->
      <input v-if="isSignUp" v-model="passwordConfirm" type="password" placeholder="Retype Password" class="input-field" @input="validatePasswordConfirm" aria-label="PasswordConfirm" />
      <!-- Display password match error during sign-up -->
      <div v-if="isSignUp && v$.passwordConfirm.$error" class="error-message">{{ v$.passwordConfirm.$errors[0]?.$message }}</div>
    </div>

    <!-- Submit button enabled only when form is valid -->
    <button type="submit" class="login-button" :disabled="!isFormValid">{{ isSignUp ? 'Sign Up' : 'Login' }}</button>

    <p v-if="error" class="error-message">{{ error }}</p>

    <!-- Toggle between Sign Up and Login -->
    <button type="button" @click="handleToggleAuthMode" class="auth-toggle-button">{{ isSignUp ? 'Already have an account? Login' : "Don't have an account? Sign Up" }}</button>

    <button type="button" class="auth-toggle-button" @click="navigateToPrivacy">Privacy Policy</button>
  </form>
</template>

<script>
import { useAuth } from '../composables/useAuth'
import ModalPopup from './ModalPopup.vue' // Import the ModalPopup component
import { ref, watch, computed } from 'vue'
import useVuelidate from '@vuelidate/core'
import { required, email as emailValidator, minLength, sameAs, helpers } from '@vuelidate/validators'
import { useDebounce } from '../composables/useDebounce'
import { useRouter } from 'vue-router'

export default {
  components: {
    ModalPopup, // Register the ModalPopup component
  },
  setup() {
    const router = useRouter()

    const minPwLen = '8'
    const { email, password, error, isSignUp, submitForm, signInWithGoogle, signInWithFacebook, signInWithTwitter, sendPasswordReset, toggleAuthMode, showForgotPassword } = useAuth(minPwLen)

    const passwordConfirm = ref('')
    const cooldownTime = ref(60) // Set cooldown time to 60 seconds
    const isCooldownActive = ref(false) // Controls if the button is disabled

    const navigateToPrivacy = () => {
      router.push('/privacy')
    }

    // Function to start the cooldown timer
    const startCooldown = () => {
      isCooldownActive.value = true
      const cooldownInterval = setInterval(() => {
        cooldownTime.value -= 1
        if (cooldownTime.value <= 0) {
          clearInterval(cooldownInterval)
          isCooldownActive.value = false
          cooldownTime.value = 60 // Reset the cooldown time
        }
      }, 1000) // Decrease time every second
    }

    // Modal state
    const isModalVisible = ref(false) // Controls modal visibility
    const promptCode = ref('') // Controls the message in the modal

    // Function to open the modal with a specific prompt code
    const openModal = (code) => {
      promptCode.value = code
      isModalVisible.value = true
    }

    // Function to close the modal
    const closeModal = () => {
      isModalVisible.value = false
      showForgotPassword.value = false
    }

    // Function to send password reset email and show modal
    const sendPasswordResetWithPopup = async () => {
      await sendPasswordReset()
      openModal('password-reset') // Open modal when password reset is triggered
      startCooldown() // Start the cooldown after sending the reset
    }

    // Debounced email for validation
    const debouncedEmail = ref('')

    // Vuelidate rules
    const rules = {
      debouncedEmail: {
        required: helpers.withMessage('Please input your email', required),
        email: helpers.withMessage('Must be a valid email', emailValidator),
      },
      password: {
        required: helpers.withMessage('Password is required', required),
        minLength: helpers.withMessage('Minimum ' + minPwLen + ' characters', minLength(minPwLen)),
      },
      passwordConfirm: {
        required: helpers.withMessage('Please confirm your password', required),
        sameAsPassword: helpers.withMessage('Passwords do not match', sameAs(password)),
      },
    }

    // Initialize Vuelidate
    const v$ = useVuelidate(rules, {
      debouncedEmail,
      password,
      passwordConfirm,
    })

    // Debounce function for email
    const updateDebouncedEmail = useDebounce((value) => {
      debouncedEmail.value = value
      v$.value.debouncedEmail.$touch()
      v$.value.debouncedEmail.$validate()
    }, 1000)

    // Watch email input and update debouncedEmail
    watch(email, (newValue) => {
      updateDebouncedEmail(newValue)
    })

    // Functions to validate password fields
    const validatePassword = () => {
      v$.value.password.$touch()
    }

    const validatePasswordConfirm = () => {
      v$.value.passwordConfirm.$touch()
    }

    // Computed property to determine if the form is valid
    const isFormValid = computed(() => {
      const validations = v$.value
      if (isSignUp.value) {
        return validations.debouncedEmail.$dirty && validations.password.$dirty && validations.passwordConfirm.$dirty && !validations.debouncedEmail.$invalid && !validations.password.$invalid && !validations.passwordConfirm.$invalid
      } else {
        return validations.debouncedEmail.$dirty && validations.password.$dirty && !validations.debouncedEmail.$invalid && !validations.password.$invalid
      }
    })

    // Modify toggleAuthMode to clear inputs
    const handleToggleAuthMode = () => {
      error.value = null
      email.value = ''
      debouncedEmail.value = ''
      password.value = ''
      passwordConfirm.value = ''
      v$.value.$reset()
      toggleAuthMode()
    }

    return {
      email,
      password,
      passwordConfirm,
      error,
      isSignUp,
      submitForm,
      signInWithGoogle,
      signInWithFacebook,
      signInWithTwitter,
      sendPasswordReset: sendPasswordResetWithPopup, // Use this version to trigger modal
      handleToggleAuthMode,
      v$,
      isFormValid,
      validatePassword,
      validatePasswordConfirm,
      isModalVisible, // Modal visibility state
      promptCode, // Modal prompt code
      closeModal, // Close modal method
      showForgotPassword, // Return this to the template
      cooldownTime,
      isCooldownActive,
      navigateToPrivacy,
    }
  },
}
</script>


