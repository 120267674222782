// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './global.css'; 

const app = createApp(App);

store.dispatch('trackAuthState'); // Track auth state globally

app.use(store);
app.use(router);
app.mount('#app');
