import { ref } from 'vue';
import { auth, googleProvider, facebookProvider, twitterProvider } from '../firebase'; // Import the Facebook provider
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, signInWithPopup, sendPasswordResetEmail } from 'firebase/auth'; // Import the reset email function
import { useRouter } from 'vue-router';

export const useAuth = (minPwLen) => {
    const email = ref('');
    const password = ref('');
    const error = ref('');
    const isSignUp = ref(false);
    const router = useRouter();
    const showForgotPassword = ref(false); 
    const submitForm = async () => {
        try {
            if (isSignUp.value) {
                console.log('Attempting sign-up...');
                const userCredential = await createUserWithEmailAndPassword(auth, email.value, password.value);
                if (userCredential.user) {
                    console.log('Navigating to menu...');
                    router.push('/menu');
                }
            } else {
                console.log('Attempting login...');
                const userCredential = await signInWithEmailAndPassword(auth, email.value, password.value);
                if (userCredential.user) {
                    console.log('Navigating to menu...');
                    router.push('/menu');
                }
            }
        } catch (err) {
            handleAuthErrors(err);
        }
    };

    const signInWithGoogle = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            if (result.user) {
                console.log('Google Sign-In successful, navigating to menu...');
                router.push('/menu');
            }
        } catch (err) {
            handleAuthErrors(err);
        }
    };

    const signInWithFacebook = async () => {
        try {
            const result = await signInWithPopup(auth, facebookProvider);
            if (result.user) {
                console.log('Facebook Sign-In successful, navigating to menu...');
                router.push('/menu');
            }
        } catch (err) {
            handleAuthErrors(err);
        }
    };

    const signInWithTwitter = async () => {
        try {
            const result = await signInWithPopup(auth, twitterProvider);
            if (result.user) {
                console.log('Twitter Sign-In successful, navigating to menu...');
                router.push('/menu');
            }
        } catch (err) {
            handleAuthErrors(err);
        }
    };



    // Forgot password function
    const sendPasswordReset = async () => {
        try {
            await sendPasswordResetEmail(auth, email.value);
            console.log('Password reset email sent');
        } catch (err) {
            handleAuthErrors(err);
        }
    };

    const handleAuthErrors = (err) => {
        console.log('error',err.code)
        switch (err.code) {
            case 'auth/email-already-in-use':
                error.value = 'Email is already in use';
                break;
            case 'auth/weak-password':
                error.value = `Password should be at least ${minPwLen} characters.`;
                break;
            case 'auth/user-not-found':
                error.value = 'Account not found';
                break;
            case 'auth/wrong-password':
                error.value = 'Incorrect password';
                showForgotPassword.value = true;  // Trigger display of forgot password
                break;
            case 'auth/invalid-email':
                error.value = 'Invalid email format';
                break;
            case 'auth/invalid-credential':
                showForgotPassword.value = true;  // Trigger display of forgot password
                error.value = 'Invalid credentials';
                break;
            case 'auth/too-many-requests':
                error.value = 'Too many login attempts';
                break;
            case 'auth/user-disabled':
                error.value = 'Account locked';
                break;
            default:
                error.value = 'Unexpected error. Try again.';
        }
    };

    const toggleAuthMode = () => {
        console.log('Toggling auth mode. Current mode:', isSignUp.value ? 'Sign-up' : 'Login');
        error.value = null;
        isSignUp.value = !isSignUp.value;
    };

    return {
        email,
        password,
        error,
        isSignUp,
        submitForm,
        signInWithGoogle,
        signInWithFacebook,
        sendPasswordReset, // Export the forgot password function
        toggleAuthMode,
        showForgotPassword,
        signInWithTwitter,
    };
};
